/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : Event Booking App Template
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2021-present initappz.
*/
export const environment = {
  production: false,
  _APP_URL: "http://SGs-Mac-mini:8080",
  APP_URL: "https://mobile1.sanpe.hu"
};
