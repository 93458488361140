import {inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, map, Observable, of, Subject} from 'rxjs';
import {TokenStorageService} from "./token-storage.service";
import {UserDTO} from "../models/auth.models";
import {environment} from "../../environments/environment";
import {EventService} from "./event.service";

const AUTH_API = environment.APP_URL;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<UserDTO>;
  public currentUser: Observable<UserDTO>;
  private _eventService = inject(EventService);

  constructor(private http: HttpClient,
              private sessionService: TokenStorageService) {
    this.currentUserSubject = new BehaviorSubject<UserDTO>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  /**
   * current user
   */
  public get currentUserValue(): UserDTO {
    return this.currentUserSubject.value;
  }

  /**
   * Performs the auth
   * @param username email of user
   * @param password password of user
   */
  login(username: string, password: string):Observable<UserDTO> {
    this._eventService.clearDashboardData();
    return this.http.post<UserDTO>(AUTH_API + '/api/login', { username, password }).pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.clear();
        localStorage.setItem('toast', 'true');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.sessionService.saveUser(user);
        this.currentUserSubject.next(user);
      }
      return user;
    }));
    /*
    return this.http.post<User>(AUTH_API + '/api/login', { username, password }).pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('toast', 'true');
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.sessionService.saveUser(user);
        this.currentUserSubject.next(user);
      }
      return user;
    }));*/
  }

  /**
   * Logout the user
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.sessionService.signOut();
    this.currentUserSubject.next(null!);
  }
}
