import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of, tap} from "rxjs";
import {CalendarDeleteRequest, CalendarRequest, CalendarResponse, EventResponse} from "../models/event.model";
import {BaseResponse} from "../models/base.model";
import {DashboardResponse} from "../models/dashboard.model";
import {BookedModel, BookingRequest} from "../models/booked.model";
import {environment} from "../../environments/environment";

const AUTH_API = environment.APP_URL;

@Injectable({ providedIn: 'root' })
export class EventService {

  private dashboardData: BehaviorSubject<DashboardResponse | null> = new BehaviorSubject<DashboardResponse | null>(null);

  constructor(private http: HttpClient) {
  }

  getEvents(): Observable<EventResponse> {
    return this.http.get<EventResponse>(AUTH_API +'/api/event');
  }
  deleteEvents(input: CalendarDeleteRequest): Observable<BaseResponse> {
    return this.http.delete<BaseResponse>(AUTH_API +'/api/event',{body: input});
  }
  setEvent(input: CalendarRequest) {
    return this.http.post<CalendarResponse>(AUTH_API +'/api/event', input);
  }
  getAllBookedEvents(): Observable<EventResponse> {
    return this.http.get<EventResponse>(AUTH_API +'/api/book');
  }
  getStudentBookedEvents(studentId: string): Observable<EventResponse> {
    return this.http.get<EventResponse>(AUTH_API +'/api/book/'+studentId);
  }

  getStudentDashboardEvents(studentId: string): Observable<DashboardResponse> {
    if (this.dashboardData.value) {
      // If data is already fetched, return it as an observable
      return of(this.dashboardData.value);
    } else {
      // Fetch data from the server and store it
      return this.http.get<DashboardResponse>(AUTH_API +`/api/dashboard/${studentId}`).pipe(
          tap(data => this.dashboardData.next(data))
      );
    }
  }

  clearDashboardData() {
    this.dashboardData.next(null);
  }

  getStudentAvailableBookedEvents(studentId: string): Observable<EventResponse> {
    return this.http.get<EventResponse>(AUTH_API +'/api/bookable/'+studentId);
  }

  addedFreeStudents(eventId: string | undefined): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(AUTH_API +'/api/addFreeStudents/'+eventId,null);
  }
  getBookedEventDetails(eventId: string): Observable<BookedModel> {
    return this.http.get<BookedModel>(AUTH_API +'/api/booked/'+eventId);
  }
  setbookStudent(input: BookingRequest, type: string) : Observable<BaseResponse> {
    return this.http.post<BaseResponse>(AUTH_API +'/api/book/'+type, input);
  }
  deletebookStudent(input: BookingRequest, type: string) : Observable<BaseResponse> {
    return this.http.delete<BaseResponse>(AUTH_API +'/api/book/'+type, {body: input} );
  }
  deleteAllEvents(customerNumber: string): Observable<BaseResponse> {
    return this.http.delete<BaseResponse>(AUTH_API +'/api/autobook/'+customerNumber);
  }
  regenerateAllEvents(customerNumber: string): Observable<BaseResponse> {
    return this.http.put<BaseResponse>(AUTH_API +'/api/autobook/'+customerNumber, null);
  }
  deleteAllEventsEverybody(students: Array<string>) {
    return this.http.delete<BaseResponse>(AUTH_API +'/api/autodelete-all', {body: students});
  }
  generateBookingEverybody(studens: Array<string>) {
    return this.http.put<BaseResponse>(AUTH_API +'/api/autobook-all',studens);
  }
}
