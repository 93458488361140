import { Injectable } from '@angular/core';
import {UserDTO} from "../models/auth.models";

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }
  signOut(): void {
    sessionStorage.clear();
  }
  public saveToken(token: string): void {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: UserDTO): void {
    sessionStorage.removeItem(USER_KEY);
    this.saveToken(user.token);
    sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): UserDTO | null {
    const user = sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }
}
