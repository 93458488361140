import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import {TokenStorageService} from "../services/token-storage.service";

const TOKEN_HEADER_KEY = 'x-auth-token';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private tokenStorageService: TokenStorageService,
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
            // add authorization header with jwt token if available
            /*
            const currentUser = this.tokenStorageService.getUser()
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`,
                    },
                });
            }

             */
        let authReq = request;
        const token = this.tokenStorageService.getToken();
        if (token != null) {
            // for Spring Boot back-end
            // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

            // for Node.js Express back-end
            authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, token) });
        }
        return next.handle(authReq);
    }
}
